import React from "react";
import {
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";
import user from '../assets/user.png'

const testimonialsData = [
  {
    name: "Chaz G",
    quote:
      "I bought these for my grand daughter and they are perfect. I could of bought a bigger size but these fit okay. Thank you for the great shoe and very fast service.",
    rating: 5,
    avatar:
      "",
  },
  {
    name: "Mary",
    quote:
      "This is a GREAT coat!! My husband was wearing the most awful jackets and I just couldn't take any more of it. So, I got him this coat.",
    rating: 5,
    avatar:
      "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(2).webp",
  },
  {
    name: "Sean Elders",
    quote:
      "These 2 we're a lot of fun to build. Lots of pieces. Very satisfying build. I highly recommend.",
    rating: 5,
    avatar:
      "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(9).webp",
  },
];

const Testimonial = () => {
  return (
    <MDBContainer className="py-5 mt-5" id='testimonials'>
      <MDBRow className="d-flex justify-content-center">
        <MDBCol md="10" xl="8" className="text-center">
          <MDBTypography tag='h1' className='text-center'>
            <span style={{ color: '#000000' }}>Testimonial</span>
          </MDBTypography>
          <p className="mb-4 pb-2 mb-md-5 pb-md-0">
            What our clients say about Lyndon Product Sales
          </p>
        </MDBCol>
      </MDBRow>
      <MDBRow className="text-center">
        {testimonialsData.map((testimonial, index) => (
          <MDBCol key={index} md="4" className="mb-5 mb-md-0">
            <div className="d-flex justify-content-center mb-4">
              <img
                src={user}
                className="rounded-circle shadow-1-strong"
                width="150"
                height="150"
                alt={`Avatar of ${testimonial.name}`}
              />
            </div>
            <h5 className="mb-3">{testimonial.name}</h5>
            <h6 className="text-primary mb-3">{testimonial.role}</h6>
            <p className="px-xl-3">
              <MDBIcon fas icon="quote-left" className="pe-2" />
              {testimonial.quote}
            </p>
            <div className="d-flex justify-content-center mb-0">
              {[...Array(Math.floor(testimonial.rating))].map((_, i) => (
                <MDBIcon
                  key={i}
                  fas
                  icon="star"
                  size="sm"
                  className="text-warning"
                />
              ))}
              {testimonial.rating % 1 !== 0 && (
                <MDBIcon
                  fas
                  icon="star-half-alt"
                  size="sm"
                  className="text-warning"
                />
              )}
            </div>
          </MDBCol>
        ))}
      </MDBRow>
    </MDBContainer>
  );
};

export default Testimonial;
