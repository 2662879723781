import React from 'react';
import {
  MDBContainer,
  MDBTypography,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardImage,
} from 'mdb-react-ui-kit';
import Illustrator1 from '../assets/illustrator-1.png';
import BG2 from '../assets/background/bg2.png';


const About = () => {
  return (
    <MDBContainer fluid className='services' id="abtlyndon">
    <MDBRow className='bg2' style={{
          backgroundImage: `url(${BG2})`}}>
      <MDBCol lg='6' md='8' sm='10' xs='12' className=''>
      <MDBContainer  >
    <MDBRow className='flex-wrap justify-content-center px-5'>
      <MDBCol md='8' lg='6' className='mt-5'>
      <MDBCard shadow='5' className='services-card'>
        <MDBCardImage src={Illustrator1} position='top' alt='...' className='h-100' style={{objectFit: "contain"}}/>
      </MDBCard>
       
      </MDBCol>
      </MDBRow>

      </MDBContainer>
      </MDBCol>
      <MDBCol lg='6' md='8' sm='10' xs='12' >
      <MDBTypography tag='h1' className='text-center text-md-start'>
        <span style={{ color: '#000000' }}>About</span> <span style={{ color: '#EA03EF' }}> Lyndon</span>
        </MDBTypography> 
          <p className='text-justify pt-5 about-content'>
          We offer a wide range of quality items and with over three years of experience in the industry, we have established ourselves as a reliable and customer-oriented business   
          </p>
      </MDBCol>
    </MDBRow>
  </MDBContainer>
  
    // <MDBContainer fluid className='about'>
    //   <MDBRow className='bg2' style={{
    //         backgroundImage: `url(${BG2})`}}>
    //     <MDBCol lg='6' md='8' sm='10' xs='12' >
    //     <MDBCard shadow='5' className='about-card'>
    //         <MDBCardImage src={Illustrator1} position='top' alt='...' className='h-100' />
    //       </MDBCard>
         
    //     </MDBCol>

    //     <MDBCol lg='6' md='8' sm='10' xs='12' className=''>
    //     <MDBContainer  >
    //      <MDBTypography tag='h1' className='mt-5'>  
    //      <span style={{ color: '#000000' }}>About</span> <span style={{ color: '#EA03EF' }}> Lyndon</span>
    //        </MDBTypography>
    //       <MDBTypography tag='div' className='pt-5 w-75'>
    //         We offer a wide range of quality items and with over three years of experience in the industry, we have established ourselves as a reliable and customer-oriented business
    //       </MDBTypography>

    //     </MDBContainer>
    //     </MDBCol>
    //   </MDBRow>
    // </MDBContainer>
  );
};

export default About;
