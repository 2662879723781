import React, { useState } from 'react';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBBtn,
  MDBCollapse,
  MDBTypography
} from 'mdb-react-ui-kit';
import logo from '../assets/logo.png';

const NavBar = () => {
  const [showBasic, setShowBasic] = useState(false);

  return (
    <MDBNavbar expand='lg' light bgColor='light' sticky className='px-5'>
      <MDBContainer fluid justify-content-between className='p-0'>
        <MDBNavbarBrand href='#' ><img style={{ height: '80px', width: '80px' }}
          src={logo}
          height='30'
          alt=''
          loading='lazy'
        />
          <MDBNavbarLink active aria-current='page' href='#'>
            <MDBTypography tag='h4'>Lyndon Product Sales</MDBTypography>
          </MDBNavbarLink>
        </MDBNavbarBrand>

        <MDBNavbarToggler
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
          onClick={() => setShowBasic(!showBasic)}
        >
          <MDBIcon icon='bars' fas />
        </MDBNavbarToggler>

        <MDBCollapse navbar show={showBasic}>
          <MDBNavbarNav className='justify-content-end align-items-center mr-auto mb-2 mb-lg-0'>
            <MDBNavbarItem>

            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink active aria-current='page' href='#homelyndon' className='navbarlinks'>
                Home
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink href='#abtlyndon' className='navbarlinks'>About</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink href='#ctglyndon' className='navbarlinks'>Catalog</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink href='#srvlyndon' className='navbarlinks'>Services</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink href='#ftrlyndon' className='navbarlinks'>Contact</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarLink className='d-flex w-auto align-items-center '>
              <a href='https://www.amazon.com/s?me=A3N3BRXCUIAL6Q&marketplaceID=ATVPDKIKX0DER' target='_blank' rel='noreferrer'>
                <MDBBtn className='btn-gradient' style={{ whiteSpace: 'nowrap' }}>
                  Amazon Shop
                </MDBBtn>
              </a>
            </MDBNavbarLink>


          </MDBNavbarNav>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  );
};

export default NavBar;
