import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardImage,
} from 'mdb-react-ui-kit';
import React from 'react';
import PR1 from '../../assets/products/1.jpg'
import PR2 from '../../assets/products/2.jpg'
import PR3 from '../../assets/products/3.jpg'
import PR4 from '../../assets/products/4.jpg'
import PR5 from '../../assets/products/5.jpg'
import PR6 from '../../assets/products/6.jpg'
import Amazon from '../../assets/amazon.jpg'

const Productitems1 = () => {
    return (
        <MDBContainer>
            <MDBRow className='row-cols-1 row-cols-md-2 row-cols-lg-3 g-4'>
                <MDBCol>
                    <MDBCard>
                        <MDBCardImage src={PR1} position='top' alt='...' className='p-10'/>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCardTitle>Columbia Men's Pike Lake Hooded Jacket</MDBCardTitle>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBCardTitle className='text-magenta p-1'>$134.95</MDBCardTitle>
                                </MDBCol>
                                <MDBCol>
                                    <a href='https://www.amazon.com/Columbia-Hooded-Winter-Repellent-Breathable/dp/B0814BT5T4/ref=sr_1_43?m=A3N3BRXCUIAL6Q&marketplaceID=ATVPDKIKX0DER&qid=1689230014&s=merchant-items&sr=1-43' target='_blank' rel='noreferrer'> <MDBCardImage src={Amazon}alt='...' className='logo-amazon' /></a>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>

                  </MDBCol>
                <MDBCol>
                    <MDBCard>
                        <MDBCardImage src={PR2} position='top' alt='...' className='p-10'/>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCardTitle>Skechers Unisex-Child D'Lites-Biggest Fan Sneaker</MDBCardTitle>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBCardTitle className='text-magenta'>$59.99</MDBCardTitle>
                                </MDBCol>
                                <MDBCol>
                                    <a href='https://www.amazon.com/Skechers-DLites-Biggest-Sneaker-Medium-Little/dp/B07DGHYC9B/ref=sr_1_50?m=A3N3BRXCUIAL6Q&marketplaceID=ATVPDKIKX0DER&qid=1689230042&s=merchant-items&sr=1-50' target='_blank' rel='noreferrer'> <MDBCardImage src={Amazon}alt='...' className='logo-amazon' /></a>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol>
                    <MDBCard>
                        <MDBCardImage src={PR3} position='top' alt='...' className='p-10'/>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCardTitle>George Foreman 9-Serving Classic Plate Electric Indoor Grill and Panni ...
                                </MDBCardTitle>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBCardTitle className='text-magenta'>$65.00</MDBCardTitle>
                                </MDBCol>
                                <MDBCol>
                                    <a href='https://www.amazon.com/George-Foreman-GRS120GT-Serving-Gray/dp/B08KSDV8BB/ref=sr_1_1?m=A3N3BRXCUIAL6Q&marketplaceID=ATVPDKIKX0DER&qid=1689230438&s=merchant-items&sr=1-1' target='_blank' rel='noreferrer'> <MDBCardImage src={Amazon}alt='...' className='logo-amazon' /></a>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
            <MDBRow className='row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 mt-4'>
                <MDBCol>
                    <MDBCard>
                        <MDBCardImage src={PR4} position='top' alt='...' className='p-10'/>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCardTitle>Bandai Hobby Star Wars 1/12 Plastic Model BB-8 & R2-D2 "Star Wars" </MDBCardTitle>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBCardTitle className='text-magenta'>$49.31</MDBCardTitle>
                                </MDBCol>
                                <MDBCol>
                                    <a href='https://www.amazon.com/Bandai-Hobby-Plastic-Model-R2-D2/dp/B0162CNVPU/ref=sr_1_20?m=A3N3BRXCUIAL6Q&marketplaceID=ATVPDKIKX0DER&qid=1689229861&s=merchant-items&sr=1-20' target='_blank' rel='noreferrer'> <MDBCardImage src={Amazon}alt='...' className='logo-amazon' /></a>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol>
                    <MDBCard>
                        <MDBCardImage src={PR5} position='top' alt='...' className='p-10'/>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCardTitle>LEGO Disney Encanto Isabela’s Magical Door 43201 Building Kit</MDBCardTitle>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBCardTitle className='text-magenta'>$25.00</MDBCardTitle>
                                </MDBCol>
                                <MDBCol>
                                    <a href='https://www.amazon.com/LEGO-Disney-Girls-Extra-2021/dp/B094R99CDB/ref=sr_1_4?m=A3N3BRXCUIAL6Q&marketplaceID=ATVPDKIKX0DER&qid=1689230757&s=merchant-items&sr=1-4' target='_blank' rel='noreferrer'> <MDBCardImage src={Amazon}alt='...' className='logo-amazon' /></a>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol>
                    <MDBCard>
                        <MDBCardImage src={PR6} position='top' alt='...' className='p-10' />
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCardTitle>Nostalgia MyMini Electric Griddle, Nonstick Perfect for Eggs ...</MDBCardTitle>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBCardTitle>$49.99</MDBCardTitle>
                                </MDBCol>
                                <MDBCol>
                                    <a href='https://www.amazon.com/Nostalgia-MyMini-Personal-Breakfast-Sandwiches/dp/B09KCPHYTY/ref=sr_1_33?m=A3N3BRXCUIAL6Q&marketplaceID=ATVPDKIKX0DER&qid=1689230972&s=merchant-items&sr=1-33' target='_blank' rel='noreferrer'> <MDBCardImage src={Amazon}alt='...' className='logo-amazon' /></a>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
};

export default Productitems1;
