import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardImage,
} from 'mdb-react-ui-kit';
import React from 'react';
import PT1 from '../../assets/products/toys/1.jpg'
import PT2 from '../../assets/products/toys/2.jpg'
import PT3 from '../../assets/products/toys/3.jpg'
import PT4 from '../../assets/products/toys/4.jpg'
import PT5 from '../../assets/products/toys/5.jpg'
import PT6 from '../../assets/products/toys/6.jpg'
import Amazon from '../../assets/amazon.jpg'

const Productitems2 = () => {
  return (
      <MDBContainer>
          <MDBRow className='row-cols-1 row-cols-md-2 row-cols-lg-3 g-4'>
          <MDBCol>
                    <MDBCard>
                        <MDBCardImage src={PT1} position='top' alt='...' className='p-10'/>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCardTitle>Got2Glow Fairy Finder - Electronic Fairy Jar Catches 30+ Virtual Fairies </MDBCardTitle>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBCardTitle className='text-magenta p-1'>$29.99 </MDBCardTitle>
                                </MDBCol>
                                <MDBCol>
                                    <a href='https://www.amazon.com/Got2Glow-Fairy-Finder-Electronic-Catches/dp/B08TMWQBPM/ref=sr_1_3?m=A3N3BRXCUIAL6Q&marketplaceID=ATVPDKIKX0DER&qid=1689232561&s=merchant-items&sr=1-3' target='_blank' rel='noreferrer'> <MDBCardImage src={Amazon}alt='...' className='logo-amazon' /></a>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>

                  </MDBCol>
                <MDBCol>
                    <MDBCard>
                        <MDBCardImage src={PT2} position='top' alt='...' className='p-10'/>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCardTitle>Schleich Horse Club Sofia's Beauties 25-Piece Horse Gift for Girls and...</MDBCardTitle>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBCardTitle className='text-magenta'>$99.99</MDBCardTitle>
                                </MDBCol>
                                <MDBCol>
                                    <a href='https://www.amazon.com/SCHLEICH-Sofias-Beauties-25-Piece-Boutique/dp/B09QH6D3GP/ref=sr_1_14?m=A3N3BRXCUIAL6Q&marketplaceID=ATVPDKIKX0DER&qid=1689231429&s=merchant-items&sr=1-14' target='_blank' rel='noreferrer'> <MDBCardImage src={Amazon}alt='...' className='logo-amazon' /></a>
                                </MDBCol>
                            </MDBRow>       
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol>
                    <MDBCard>
                        <MDBCardImage src={PT3} position='top' alt='...' className='p-10'/>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCardTitle>Carrera RC Nintendo Mario Kart 2.4 GHz Mini Collectible Radio Remote ...
                                </MDBCardTitle>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBCardTitle className='text-magenta'>$34.99</MDBCardTitle>
                                </MDBCol>
                                <MDBCol>
                                    <a href='https://www.amazon.com/Carrera-Nintendo-Collectible-Control-Vehicle/dp/B08YRXHVR5/ref=sr_1_15?m=A3N3BRXCUIAL6Q&marketplaceID=ATVPDKIKX0DER&qid=1689231581&s=merchant-items&sr=1-15' target='_blank' rel='noreferrer'> <MDBCardImage src={Amazon}alt='...' className='logo-amazon' /></a>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
            <MDBRow className='row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 mt-4'>
                <MDBCol>
                    <MDBCard>
                        <MDBCardImage src={PT4} position='top' alt='...' className='p-10'/>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCardTitle>LEGO Marvel Shang-Chi Battle at The Ancient Village 76177 Building Kit</MDBCardTitle>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBCardTitle className='text-magenta'>$35.00</MDBCardTitle>
                                </MDBCol>
                                <MDBCol>
                                    <a href='https://www.amazon.com/LEGO-Shang-Chi-Ancient-Village-Building/dp/B08NFDW8CT/ref=sr_1_6?m=A3N3BRXCUIAL6Q&marketplaceID=ATVPDKIKX0DER&qid=1689231617&s=merchant-items&sr=1-6' target='_blank' rel='noreferrer'> <MDBCardImage src={Amazon}alt='...' className='logo-amazon' /></a>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol>
                    <MDBCard>
                        <MDBCardImage src={PT5} position='top' alt='...' className='p-10'/>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCardTitle>Bandai Star Wars Plastic Model Death Star II 1/2,700,000 </MDBCardTitle>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBCardTitle className='text-magenta'>$39.99</MDBCardTitle>
                                </MDBCol>
                                <MDBCol>
                                    <a href='https://www.amazon.com/Bandai-Plastic-Model-Death-Destroyer/dp/B07CMVYJS8/ref=sr_1_17?m=A3N3BRXCUIAL6Q&marketplaceID=ATVPDKIKX0DER&qid=1689231927&s=merchant-items&sr=1-17' target='_blank' rel='noreferrer'> <MDBCardImage src={Amazon}alt='...' className='logo-amazon' /></a>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol>
                    <MDBCard>
                        <MDBCardImage src={PT6} position='top' alt='...' className='p-10' />
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCardTitle>Nostalgia MyMini Electric Griddle, Nonstick Perfect for Eggs ...</MDBCardTitle>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBCardTitle>$49.99</MDBCardTitle>
                                </MDBCol>
                                <MDBCol>
                                    <a href='https://www.amazon.com/Nostalgia-MyMini-Personal-Breakfast-Sandwiches/dp/B09KCPHYTY/ref=sr_1_33?m=A3N3BRXCUIAL6Q&marketplaceID=ATVPDKIKX0DER&qid=1689230972&s=merchant-items&sr=1-33' target='_blank' rel='noreferrer'> <MDBCardImage src={Amazon}alt='...' className='logo-amazon' /></a>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
          </MDBRow>
      </MDBContainer>
  );
};

export default Productitems2;
