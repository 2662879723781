import React from 'react';
import {
  MDBContainer,
  MDBTypography,
  MDBRow,
  MDBCol,
  MDBListGroup,
  MDBCardImage,
  MDBCard
} from 'mdb-react-ui-kit';
import Listing from '../assets/icons/listing.png';
import Brand from '../assets/icons/brand.png';
import Expert from '../assets/icons/expert.png';
import PPC from '../assets/icons/ppc.png';
import Illustrator2 from '../assets/illustrator-2.png';
import BG3 from '../assets/background/bg3.png';

const Services = () => {
  return (
      
    <MDBContainer fluid className='services' id='srvlyndon'>
      <MDBRow className='bg2' style={{
            backgroundImage: `url(${BG3})`}}>
    

        <MDBCol lg='6' md='8' sm='10' xs='12' className=''>
        <MDBContainer  >
      
      <MDBRow className='flex-wrap justify-content-center px-5'>
        <MDBCol md='8' lg='6' className='mt-5'>
          <MDBListGroup>
          <MDBTypography tag='h1' className='text-center text-md-start'>
          <span style={{ color: '#000000' }}>Our</span> <span style={{ color: '#EA03EF' }}>Services</span>
          </MDBTypography> 
            <p className='text-justify'>
              At <span style={{ color: '#4E4C4C', fontWeight: 'bold' }}>Lyndon Product Sales, LLC,</span> we go beyond just being an online retailer.
              <br />
              We also provide a range of additional services to support brands in their journey of selling on Amazon.
            </p>

            <div className="d-flex flex-wrap align-items-center flex-column flex-md-row gap-3 pt-2">
              <img src={Listing} alt='...' className='shadow-4 box-gradient' />
              <p className="text-center text-md-start">Listing Optimization</p>
            </div>
            <div className="d-flex flex-wrap align-items-center flex-column flex-md-row gap-3 pt-2">
              <img src={Brand} alt='...' className='shadow-4 box-gradient' />
              <p className="text-center text-md-start">PPC Advertising</p>
            </div>
            <div className="d-flex flex-wrap align-items-center flex-column flex-md-row gap-3 pt-2">
              <img src={Expert} alt='...' className='shadow-4 box-gradient' />
              <p className="text-center text-md-start">Expert Advice</p>
            </div>
            <div className="d-flex flex-wrap align-items-center flex-column flex-md-row gap-3 pt-2">
              <img src={PPC} alt='...' className='shadow-4 box-gradient' />
              <p className="text-center text-md-start">Brand Management</p>
            </div>

          </MDBListGroup>
        </MDBCol>
        </MDBRow>

        </MDBContainer>
        </MDBCol>
        <MDBCol lg='6' md='8' sm='10' xs='12' >
        <MDBCard shadow='5' className='services-card'>
            <MDBCardImage src={Illustrator2} position='top' alt='...' className='h-100' style={{objectFit: "contain"}}/>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>



  );
};

export default Services;
