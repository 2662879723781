import React, { useState } from 'react';
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBContainer,
  MDBTypography,
  MDBRow,
  MDBCol
} from 'mdb-react-ui-kit';
import ProductItems1 from '../Products/ProductItems1';
import ProductItems2 from '../Products/ProductItems2';
import ProductItems3 from '../Products/ProductItems3';

const ProductLine = () => {
  const [basicActive, setBasicActive] = useState('tab1');

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

  return (
    <MDBContainer style={{ margin: '0 auto' }} className='mt-5' >
      <MDBRow className='align-items-center'>
        <MDBCol>
          <MDBTypography tag='h1' className='text-center' id='ctglyndon'>
            <span style={{ color: '#000000' }}>Product</span>{' '}
            <span style={{ color: '#EA03EF' }}>Catalog</span>
          </MDBTypography>
        </MDBCol>
        <MDBCol>
          <MDBTabs className='mb-3 custom-tabs mt-5 tabs-large'>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
                All
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'}>
                Toys
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleBasicClick('tab3')} active={basicActive === 'tab3'}>
                Home & Appliances
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>

        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol>
          <MDBTabsContent className='justify-content-center'>
            <MDBTabsPane show={basicActive === 'tab1'}>
              <ProductItems1 />
            </MDBTabsPane>
            <MDBTabsPane show={basicActive === 'tab2'}>
              <ProductItems2 />
            </MDBTabsPane>
            <MDBTabsPane show={basicActive === 'tab3'}>
              <ProductItems3 />
            </MDBTabsPane>
          </MDBTabsContent>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default ProductLine;
