
import NavBar from './components/NavBar';
import './App.css';
import Hero from './components/Hero';
import Footer from './components/Footer';
import ProductLine from './components/Products/ProductLine';
import Services from './components/Services';
import Testimonial from './components/Testimonial';
import About from './components/About';
import LandingPage from './components/LandingPage';

function App() {
  return (
    <div className="App">
     <NavBar/>
     <LandingPage/>
     <Hero/>
     <About/>
     <ProductLine/>
     <br /><br />
     <Services/>
     <br /><br />
     <Testimonial/>
     <br /><br />
     <Footer/>
     
    </div>
  );
}

export default App;
