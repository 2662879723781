import React from 'react';
import img1 from '../assets/products/1.png';
import {
  MDBCard,
  MDBCardImage,
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBTypography
} from 'mdb-react-ui-kit';
import PAINT from '../assets/brushpaint.png';


const LandingPage = () => {
  return (

    <MDBContainer fluid style={{height: "700px"}} id='homelyndon'>
      <MDBRow className='d-flex justify-content-center gap-3'>
        <MDBCol>
          <MDBContainer fluid className='content-text' style={{
            backgroundImage: `url(${PAINT})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'
          }}>
            <MDBTypography tag='div' className='display-6 pb-3 mb-3 mt-5' style={{ color: '#EA03EF' }}>
              Welcome To
            </MDBTypography>

            <MDBTypography tag='div' className='display-3 pb-3 mb-3'>
              Lyndon Product
              <br />
              Sales, LLC
            </MDBTypography>

            <MDBTypography tag='div' className='h5 pb-3 mb-3 w-75'>
              We are dedicated to providing exceptional products and exceptional services.
            </MDBTypography>

            <MDBBtn href='https://www.amazon.com/s?i=merchant-items&me=A3N3BRXCUIAL6Q&page=3&marketplaceID=ATVPDKIKX0DER&qid=1689233839&ref=sr_pg_3' target='_blank' rel='noreferrer' className='btn-gradient'>Shop Now!</MDBBtn>

          </MDBContainer>
        </MDBCol>

        <MDBCol lg='6' md='6' sm='12' xs='12' className='d-flex justify-content-end h-400' >
          <div className="banner-right">
            <MDBCard shadow='5' className='card-hero position-absolute' >
              <MDBCardImage src={img1} position='top' alt='...' className='h-100' style={{ 
                objectFit: "contain",
                maxHeight: "100%",
                width: '500px !important'
                 }} />
                      

            </MDBCard>
              
          </div>
          
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default LandingPage;