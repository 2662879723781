import React from 'react';
import { MDBFooter, MDBRow, MDBCol, MDBIcon, MDBNavbarBrand } from 'mdb-react-ui-kit';
import '../App.css';
import logo from '../assets/logo.png';
import FooterBg from '../assets/background/footer.png'

const Footer = () => {
  return (
    <MDBFooter className='text-center text-lg-start text-muted' id='ftrlyndon'>
      <img src={FooterBg} alt="" className='img-fluid'
      style={{ objectFit: 'cover', width: '100vw', maxWidth: '100%', height: 'auto' }}
      />

      <section className=''>
        <div className='text-center text-md-start  footer'>
          
          <MDBRow className='mt-3 d-flex justify-content-center'>
            <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
              <div className='d-flex align-items-center'>
                <MDBNavbarBrand href='#' className='mt-1'>
                  <img style={{ height: '80px', width: '80px' }} src={logo} alt='' />
                </MDBNavbarBrand>
                <h6 className='text-uppercase fw-bold mb-4 ms-2'>
                  <br />
                  Lyndon Product Sales, LLC
                </h6>
              </div>

              <p className='mt-4' style={{ overflowWrap: 'break-word' }}>
                We go beyond just being an online retailer. We also provide a range of additional services to support brands in their journey of selling on Amazon.
              </p>
            </MDBCol>

            <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>
                <br />
                Helpful Links
              </h6>
              <p>
                <a href='#homelyndon' className='text-reset'>
                  Home
                </a>
              </p>
              <p>
                <a href='#abtlyndon' className='text-reset'>
                  About
                </a>
              </p>
              <p>
                <a href='#ctglyndon' className='text-reset'>
                  Product Catalog
                </a>
              </p>
              <p>
                <a href='#srvlyndon' className='text-reset'>
                  Our Services
                </a>
              </p>
              <p>
                <a href='#testimonials' className='text-reset'>
                  Testimonials
                </a>
              </p>
            </MDBCol>

            <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase text-center fw-bold mb-4'>
                <br />
                Contact Us
              </h6>
              <div className='text-center email-container'>
                <p>
                  <MDBIcon icon='phone' className='me-3' />
                  1-641-220-7712
                </p>
                <p>
                  <MDBIcon icon='home' className='me-2' />
                  Marble Rock, IA, 50653
                </p>
                <p>
                  <MDBIcon icon='envelope' className='me-3' />
                  rebekah@lyndonproductsalesllc.com
                </p>
                <p>alyssa@lyndonproductsalesllc.com</p>
              </div>
            </MDBCol>
          </MDBRow>
        </div>
      </section>

      <div className='text-center p-4 footer' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
       <p>© 2023 Lyndon Product Sales, LLC. All Rights Reserved.</p> 
      </div>
    </MDBFooter>
  );
};

export default Footer;
