import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardImage,
} from 'mdb-react-ui-kit';
import React from 'react';
import PH1 from '../../assets/products/home/1.jpg'
import PH2 from '../../assets/products/home/2.jpg'
import PH3 from '../../assets/products/home/3.jpg'
import PH4 from '../../assets/products/home/4.jpg'
import PH5 from '../../assets/products/3.jpg'
import PH6 from '../../assets/products/6.jpg'
import Amazon from '../../assets/amazon.jpg'

const Productitems3 = () => {
  return (
      <MDBContainer>
          <MDBRow className='row-cols-1 row-cols-md-2 row-cols-lg-3 g-4'>
          <MDBCol>
                    <MDBCard>
                        <MDBCardImage src={PH1} position='top' alt='...' className='p-10'/>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCardTitle>Chicago Metallic Nonstick Roaster with Floating Rack</MDBCardTitle>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBCardTitle className='text-magenta p-1'>$69.99</MDBCardTitle>
                                </MDBCol>
                                <MDBCol>
                                    <a href='https://www.amazon.com/Chicago-Metallic-Nonstick-Floating-18x13-Inch/dp/B09DD874XV/ref=sr_1_19?m=A3N3BRXCUIAL6Q&marketplaceID=ATVPDKIKX0DER&qid=1689233031&s=merchant-items&sr=1-19' target='_blank' rel='noreferrer'> <MDBCardImage src={Amazon}alt='...' className='logo-amazon' /></a>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>

                  </MDBCol>
                <MDBCol>
                    <MDBCard>
                        <MDBCardImage src={PH2} position='top' alt='...' className='p-10'/>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCardTitle>Granite Ware Stainless Steel Deluxe Food Mill, 2-Quart</MDBCardTitle>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBCardTitle className='text-magenta'>$49.99</MDBCardTitle>
                                </MDBCol>
                                <MDBCol>
                                    <a href='https://www.amazon.com/Granite-Ware-Stainless-Deluxe-2-Quart/dp/B00C1WRG66/ref=sr_1_25?m=A3N3BRXCUIAL6Q&marketplaceID=ATVPDKIKX0DER&qid=1689233031&s=merchant-items&sr=1-25' target='_blank' rel='noreferrer'> <MDBCardImage src={Amazon}alt='...' className='logo-amazon' /></a>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol>
                    <MDBCard>
                        <MDBCardImage src={PH3} position='top' alt='...' className='p-10'/>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCardTitle>Proctor Silex 4-Slice Non-Stick Belgian Waffle Maker 
                                </MDBCardTitle>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBCardTitle className='text-magenta'>$65.99</MDBCardTitle>
                                </MDBCol>
                                <MDBCol>
                                    <a href='https://www.amazon.com/Proctor-Silex-26051-Belgian-Waffle/dp/B076859QMB/ref=sr_1_26?m=A3N3BRXCUIAL6Q&marketplaceID=ATVPDKIKX0DER&qid=1689233839&s=merchant-items&sr=1-26' target='_blank' rel='noreferrer'> <MDBCardImage src={Amazon}alt='...' className='logo-amazon' /></a>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
            <MDBRow className='row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 mt-4'>
                <MDBCol>
                    <MDBCard>
                        <MDBCardImage src={PH4} position='top' alt='...' className='p-10'/>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCardTitle>Star Wars LSW-300CN Pancake Maker, White, One Size </MDBCardTitle>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBCardTitle className='text-magenta'>$62.00</MDBCardTitle>
                                </MDBCol>
                                <MDBCol>
                                    <a href='https://www.amazon.com/Star-Wars-LSW-300CN-Pancake-Maker/dp/B078FP4MC4/ref=sr_1_34?m=A3N3BRXCUIAL6Q&marketplaceID=ATVPDKIKX0DER&qid=1689233889&s=merchant-items&sr=1-34' target='_blank' rel='noreferrer'> <MDBCardImage src={Amazon}alt='...' className='logo-amazon' /></a>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol>
                 <MDBCard>
                        <MDBCardImage src={PH5} position='top' alt='...' className='p-10'/>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCardTitle>George Foreman 9-Serving Classic Plate Electric Indoor Grill and Panni ...
                                </MDBCardTitle>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBCardTitle className='text-magenta'>$65.00</MDBCardTitle>
                                </MDBCol>
                                <MDBCol>
                                    <a href='https://www.amazon.com/George-Foreman-GRS120GT-Serving-Gray/dp/B08KSDV8BB/ref=sr_1_1?m=A3N3BRXCUIAL6Q&marketplaceID=ATVPDKIKX0DER&qid=1689230438&s=merchant-items&sr=1-1' target='_blank' rel='noreferrer'> <MDBCardImage src={Amazon}alt='...' className='logo-amazon' /></a>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol>
                    <MDBCard>
                        <MDBCardImage src={PH6} position='top' alt='...' className='p-10' />
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCardTitle>Nostalgia MyMini Electric Griddle, Nonstick Perfect for Eggs ...</MDBCardTitle>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <MDBCardTitle>$49.99</MDBCardTitle>
                                </MDBCol>
                                <MDBCol>
                                    <a href='https://www.amazon.com/Nostalgia-MyMini-Personal-Breakfast-Sandwiches/dp/B09KCPHYTY/ref=sr_1_33?m=A3N3BRXCUIAL6Q&marketplaceID=ATVPDKIKX0DER&qid=1689230972&s=merchant-items&sr=1-33' target='_blank' rel='noreferrer'> <MDBCardImage src={Amazon}alt='...' className='logo-amazon' /></a>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
          </MDBRow>
      </MDBContainer>
  );
};

export default Productitems3;
