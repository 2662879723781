import React from 'react';
import {
  MDBTypography,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCardImage,
} from 'mdb-react-ui-kit';
import Shop from '../assets/icons/shop.png';
import Bag from '../assets/icons/bag.png';
import Support from '../assets/icons/support.png';


const Hero = () => {
  return (
    <MDBContainer className='pt-100 hero'>
      <MDBTypography tag='h1' className='text-center mt-5'>
        <span style={{ color: '#000000' }}>A Trusted Online</span> <span style={{ color: '#EA03EF' }}> Retailer</span> </MDBTypography>
      <MDBTypography className='lead mb-0 text-center' style={{ color: '#000000' }}>
        “Lyndon Product Sales, LLC”
      </MDBTypography>


      <MDBContainer fluid className='mt-5'>
        <MDBRow className='text-center'>
          <MDBCol md='4' className='d-flex my-2'>
            <MDBCardImage src={Shop} alt='...' className='shadow-4 box-gradient' />
            <MDBTypography className='d-flex justify-content-center align-items-center'>
              &nbsp; Online retailer in Amazon shop
            </MDBTypography>
          </MDBCol>
          <MDBCol md='4' className='d-flex my-2'>
            <MDBCardImage src={Bag} alt='...' className='shadow-4 box-gradient' />
            <MDBTypography className='d-flex justify-content-center align-items-center'>
              &nbsp; With a wide range of best quality products
            </MDBTypography>
          </MDBCol>
          <MDBCol md='4' className='d-flex my-2'>
            <MDBCardImage src={Support} alt='...' className='shadow-4 box-gradient' />
            <MDBTypography className='d-flex justify-content-center align-items-center'>
              &nbsp; 24/7 Customer Support
            </MDBTypography>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <br /><br />
    </MDBContainer>
  );
};

export default Hero;
